import React, { useState, useEffect } from 'react';
import { style } from './styles';
import { Table } from 'react-bootstrap';
// import swal from "sweetalert";

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import Firebase from '../../config/firebase';

//Methods
// import {
//   getFeedbacks
// } from "../../config/firebase";

const Reviews = (props) => {
  const [state, setState] = useState({
    feedbacks: [],
    loader: false,
  });

  const getAllFeedbacks = async () => {
    try {
      const feedbackArray = [];
      const feedbacks = await Firebase.firestore().collection('feedback').get();
      if (!feedbacks.docs.length) {
        return;
      }

      for (var feedback of feedbacks.docs) {
        const data = feedback.data();
        data.key = feedback.id;
        feedbackArray.push(data);
        if (feedbackArray.length === feedbacks.docs.length) {
          setState({ ...state, feedbacks: feedbackArray });
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllFeedbacks();
  }, []);

  // componentDidMount() {
  //   this.getAllFeedbacks();
  // }

  // getAllFeedbacks = async () => {
  //   try {
  //     let temp = [];
  //     const response = await getFeedbacks();
  //     if (response.size) {
  //       response.forEach(doc => {
  //         temp.push(doc.data());
  //         this.setState({
  //           feedbacks: temp,
  //           loader: false
  //         });
  //       });
  //     } else {
  //       this.setState({
  //         feedbacks: temp,
  //         loader: false
  //       });
  //     }
  //   } catch (error) {
  //     this.setState({
  //       feedbacks: [],
  //       loader: false
  //     });
  //     console.log("TCL: Reviews -> componentDidMount -> error", error);
  //   }
  // };

  const { feedbacks, loader } = state;
  const { history } = props;
  return (
    <div>
      <SideBar history={history} activeScreen='reviews' />
      <Header history={history} />

      <div style={style.mainContainer}>
        <div style={style.tableContainer}>
          <Table striped bordered style={{ width: '95%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Email</th>
                <th>Feedback</th>
              </tr>
            </thead>
            {loader ? (
              <div style={style.loaderWrapper}>Loading.....</div>
            ) : (
              <React.Fragment>
                {!feedbacks.length ? (
                  <div style={style.loaderWrapper}>
                    Feedbacks Not Found.....
                  </div>
                ) : (
                  <tbody>
                    {feedbacks.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{value.email}</td>
                          <td style={{ wordBreak: 'break-all' }}>
                            {value.feedback}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </React.Fragment>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
