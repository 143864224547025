import Firebase from '../../config/firebase';

export const getAllCuisines = () => async (dispatch) => {
  try {
    const cuisinesArray = [];
    const cuisines = await Firebase.firestore().collection('cuisine').get();
    if (!cuisines.docs.length) {
      return;
    }
    for (var cuisine of cuisines.docs) {
      const data = cuisine.data();
      data.key = cuisine.id;
      cuisinesArray.push(data);
      if (cuisinesArray.length === cuisines.docs.length) {
        dispatch({ type: 'GET_ALL_CUISINE', payload: cuisinesArray });
      }
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const addCuisine = (
  cuisine,
  closeModal,
  modalTitle,
  updateKey
) => async (dispatch) => {
  try {
    if (modalTitle === 'Add') {
      await Firebase.firestore().collection('cuisine').add({ name: cuisine });
      dispatch(getAllCuisines());
      closeModal();
    } else {
      await Firebase.firestore()
        .collection('cuisine')
        .doc(updateKey)
        .update({ name: cuisine });
      dispatch(getAllCuisines());
      closeModal();
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteCuisine = (key) => async (dispatch) => {
  try {
    await Firebase.firestore().collection('cuisine').doc(key).delete();
    dispatch(getAllCuisines());
  } catch (err) {
    console.log(err.message);
  }
};
