export const style = {
  videoWindow: {
    tect: "center",

    marginLeft: 230,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputContainer: {
    marginLeft: 370,
    marginTop: 15,
  },
};
