export const style = {
  mainContainer: {

    width: "100%",
    height: "10vh",
    display: "flex",
    justifyContent: "center"
  },
  tableContainer: {
    width: "83%",
    height: "87vh",
    paddingBottom: 60,
    marginLeft: 220,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
    overflow: "scroll",
    overflowX: "hidden"
  },
  loaderWrapper: {
    width: "79%",
    height: 50,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  dropdown: {
    lineHeight: 2,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
    marginTop: 20

  },
  monthlySub: {

    width: "100%",
    height: "10vh",
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
    marginTop: 20
  }
};
