const initialState = {
  users: [],
  loading: false,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_ALL_USERS': {
      return { ...state, users: action.payload };
    }

    default: {
      return state;
    }
  }
}

export default userReducer;
