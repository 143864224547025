import { Colors } from "../../utils";

export const style = {
  headerContainer: {
    width: "100%",
    height: 50,
    backgroundColor: Colors.background,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 50,
    marginTop: 4
  },
  secondaryTitle: {
    color: Colors.skyBlue
  },
  btnLogout: {
    width: 80,
    height: 40,
    marginRight: 10
  },
  menuButtonContainer: {
    width : 30,
    height : 20,
    // backgroundColor : Colors.skyBlue,
    marginLeft : 9,
    cursor : 'pointer',
    display : "flex",
    flexDirection : "column",
    justifyContent : "space-around",
    alignItems : "center"
  },
  menuIcons: {
    width : "70%",
    height : 3,
    backgroundColor : Colors.skyBlue
  }
};
