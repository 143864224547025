export const style = {
  mainContainer: {
    width: "100%",
    height: "75vh",
    display: "flex",
    justifyContent: "center"
  },
  tableContainer: {
    width: "83%",
    height: "87vh",
    marginLeft: 220,
    display: "flex",
    paddingBottom : 60,
    justifyContent: "center",
    marginTop: 10,
    overflow: "scroll",
    overflowX: "hidden"
  },
  loaderWrapper: {
    width: "79%",
    height: 50,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};
