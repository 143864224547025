import React from "react";
import { style } from "./styles";
// import { createAccount, addAdmin } from "../../config/firebase";
import { Form, Button, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/images/logo.png";

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      email: "",
      pass: "",
      cpass: "",
      loader: false,
      buttonLoader: false
    };
  }

  signup = async () => {
    const { history } = this.props;
    const { fullName, email, pass, cpass } = this.state;

    if (!fullName) {
      this.errorToast("Enter Full Name");
      return;
    }

    if (!email) {
      this.errorToast("Enter Email");
      return;
    }

    if (!pass) {
      this.errorToast("Enter Password");
      return;
    }

    if (!cpass) {
      this.errorToast("Enter Confirm Password");
      return;
    }

    if (pass !== cpass) {
      this.errorToast("Password not matched");
      return;
    }

    // this.setState({ buttonLoader: true });
    // try {
    //   const response = await createAccount(email, pass);
    //   const userId = response.user.uid;
    //   const payload = {
    //     fullName,
    //     email,
    //     userId
    //   };
    //   await addAdmin(userId, payload);
    //   this.flushState();
    //   history.replace("/login");
    // } catch (error) {
    //   this.errorToast(error.message);
    //   this.flushState();
    // }
  };

  nevigate = () => {
    const { history } = this.props;
    history.push("/login");
  };

  errorToast = message => toast.error(message, { containerId: "Error" });

  flushState = () => {
    this.setState({
      fullName: "",
      email: "",
      pass: "",
      cpass: "",
      buttonLoader: false
    });
  };

  render() {
    const { fullName, email, pass, cpass, buttonLoader } = this.state;
    return (
      <div>
        <ToastContainer
          containerId={"Error"}
          closeOnClick={false}
          closeButton={false}
          draggable={false}
        />
        <div style={style.mainContainer}>
          <div style={style.authWrapper}>
            <div style={style.authHeader}>
              <img src={logo} style={{ width: 75, height: 75, marginRight: 10 }} />
              <div style={style.borderWrapper}>
                <p style={style.headerTitle}>
                  Welcome to <span style={style.makeBold}>Admin</span>
                  <span style={style.changeColor}> PANEL</span>
                </p>
                <p style={style.headerSubHeading}>Create your account easily</p>
              </div>
            </div>
            <div style={style.fieldsWrapper}>
              <Form>
                <Form.Group controlId="formBasicFullNamel">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    value={fullName}
                    onChange={e => this.setState({ fullName: e.target.value })}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={pass}
                    onChange={e => this.setState({ pass: e.target.value })}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicConfirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    value={cpass}
                    onChange={e => this.setState({ cpass: e.target.value })}
                  />
                </Form.Group>
                {!buttonLoader ? <Button
                  variant="primary"
                  type="button"
                  block
                  onClick={this.signup}
                >
                  Signup
                </Button> : <Button variant="primary" disabled block>
                    <Spinner
                      as="span"
                      animation="border"
                      style={{ width: '20px', height: "20px" }}
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}Loading...
                </Button>}

                <Button
                  variant="outline-primary"
                  type="button"
                  style={style.btnCreateAccount}
                  onClick={this.nevigate}
                >
                  Already have Account
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signup;
