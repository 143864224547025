import { Colors } from "../../utils";

export const style = {
  buttonContainer: {
    width : "100vw",
    height : "auto",
    position : "absolute",
    bottom  : 0,
    display : "flex",
    justifyContent : "flex-end",
    alignItems : "center"
  },
  addButton: {
    width : 50,
    height : 50,
    borderRadius : 100,
    backgroundColor : Colors.skyBlue,
    margin : 40,
    cursor  : "pointer",
    display : "flex",
    justifyContent : "center",
    alignItems : "center",
    paddingBottom : 8,
    overflow: 'hidden'
  },
  addButtonText: {
    fontSize : 40,
    margin : 0,
    fontWeight : "bold",
    color : Colors.white
  }
};
