import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

//Screens
import Login from "../screens/Login";
import Signup from "../screens/Signup";
import Users from "../screens/Users";
import Cuisine from "../screens/Cuisine";
import Reviews from "../screens/Reviews";
import Prices from "../screens/Prices";
import Dashboard from "../screens/Dashboard";
import Slides from "../screens/Slides";
import ForgotPassword from "../screens/ForgotPassword";
import Interview from "../screens/Interview";
import Reports from "../screens/Reports";


const Routers = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/cuisine" component={Cuisine} />
          <Route exact path="/reviews" component={Reviews} />
          <Route exact path="/prices" component={Prices} />
          <Route exact path="/slides" component={Slides} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/interview" component={Interview} />
          <Route exact path="/Reports" component={Reports} />

        </Switch>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Routers;
