export const style = {
  mainContainer: {
    width: "100%",
    height: "75vh",
    display: "flex",
    justifyContent: "center"
  },
  tableContainer: {
    width: "83%",
    height : "87vh",
    marginLeft: 220,
    display: "flex",
    justifyContent: "center",
    paddingBottom : 60,
    marginTop : 10,
    overflow : "scroll",
    overflowX: "hidden"
  },
  featuredSaleContainer: {
    height: 40,
    marginLeft: 3,
    display: "flex",
    justifyContent: "space-around"
  },
  loaderWrapper: {
    width : "79%",
    height : 50,
    position : "absolute",
    display : "flex",
    justifyContent : "center",
    alignItems : "center"
  }
};
