import React from "react";
import { style } from "./styles";
import { connect } from "react-redux";
import { Form, Jumbotron, Button, Row, Col, Spinner } from "react-bootstrap";
import swal from "sweetalert";

// Components
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";

//Methods
// import {
//   getAdmin,
//   editAdmin,
//   changePassword,
//   logout
// } from "../../config/firebase";

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      name: 'Admin',// ''
      changeName: '',
      newPassword: "",
      loader: false
    }
  }

  // async componentWillMount() {
  //   let adminId = localStorage.getItem('userId');
  //   let response = await getAdmin(adminId);
  //   this.setState({ changeName: response.data().fullName, name: response.data().fullName });
  // }

  // updateName = async _ => {
  //   let adminId = localStorage.getItem('userId');
  //   let { changeName } = this.state;
  //   if (!changeName) return swal({
  //     icon: "error",
  //     text: "Please enter the name"
  //   });
  //   try {
  //     this.setState({ loader: true });
  //     await editAdmin(adminId, { fullName: changeName });
  //     swal({
  //       icon: "success",
  //       text: "Name successfully changed!"
  //     });
  //     this.setState({ loader: false });
  //   } catch (error) {
  //     swal({
  //       icon: "error",
  //       text: error.message
  //     });
  //     this.setState({ loader: false });
  //   }
  // }

  // changePassword = async () => {
  //   const { newPassword } = this.state;
  //   const { history } = this.props;
  //   try {
  //     if (!newPassword) {
  //       return swal({
  //         icon: "error",
  //         text: "Please enter password!"
  //       });
  //     }
  //     this.setState({ loader: true });
  //     await changePassword(newPassword);
  //     this.setState({ newPassword: "", loader: false });
  //     swal({
  //       icon: "success",
  //       text: "Password changes please login again"
  //     });
  //     await logout();
  //     localStorage.removeItem("userId");
  //     history.replace("/login");
  //   } catch (error) {
  //     this.setState({ loader: false });
  //     swal({
  //       icon: "error",
  //       text: error.message
  //     });
  //   }
  // }

  render() {
    const { history } = this.props;
    let { changeName, name, newPassword, loader } = this.state;
    return (
      <div>
        <SideBar history={history} />
        <Header history={history} />
        <div style={style.mainContainer}>
          <Jumbotron>
            <h5 style={style.nameHeading}>Welcome, {name} </h5>
            <br />
            <Form>
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3" style={style.formLabel}>
                  Edit Name:
                </Form.Label>
                <Col sm="6">
                  <Form.Control onChange={e => this.setState({ changeName: e.target.value })} value={changeName} type="text" placeholder="Edit Name" />
                </Col>
                <Col sm="3">
                  {!loader ? <Button
                    variant="primary"
                    // onClick={this.updateName}
                  >
                    Update
                  </Button> : <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        style={{ width: '20px', height: "20px" }}
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}Loading...
                </Button>}
                </Col>
              </Form.Group>
            </Form>
            <br />
            <Form>
              <Form.Group as={Row} controlId="name">
                <Form.Label column sm="3" style={style.formLabel}>
                  Password:
                </Form.Label>
                <Col sm="6">
                  <Form.Control type="password" placeholder="New Password"
                    value={newPassword}
                    onChange={e => this.setState({ newPassword: e.currentTarget.value })}
                  />
                </Col>
                <Col sm="3">
                  {!loader ? <Button
                    variant="primary"
                    // onClick={this.changePassword}
                  >
                    Change Password
                  </Button> : <Button variant="primary" disabled>
                      <Spinner
                        as="span"
                        animation="border"
                        style={{ width: '20px', height: "20px" }}
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />{" "}Loading...
                </Button>}
                </Col>
              </Form.Group>
            </Form>
          </Jumbotron>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
