import firebase from 'firebase';
import { firebaseCredentials } from './credentials';

import 'firebase/firestore';
const Firebase = firebase.initializeApp(firebaseCredentials);

export default Firebase;

// const provider = new firebase.auth.GoogleAuthProvider();

// const auth = firebase.auth();
// const db = firebase.firestore();
// const storage = firebase.storage();
// export const messaging = firebase.messaging();

// export const createAccount = (email, password) => {
//   return auth.createUserWithEmailAndPassword(email, password);
// };

// export const signIn = (email, password) => {
//   return auth.signInWithEmailAndPassword(email, password);
// };

// export const editAdmin = (userId, data) => {
//   return db
//     .collection("admins")
//     .doc(userId)
//     .set(data, { merge: true });
// };

// export const updateToken = (adminToken, userId) => {
//   return db
//     .collection("admins")
//     .doc(userId)
//     .set({ adminToken }, { merge: true });
// }

// export const googleAuth = () => {
//   return auth.signInWithPopup(provider);
// };

// export const logout = () => {
//   return auth.signOut();
// };

// export const addAdmin = (userId, payload) => {
//   return db
//     .collection("admins")
//     .doc(userId)
//     .set(payload);
// };

// export const getAllUsers = () => {
//   return db.collection("users").get();
// };

// export const changeUserStatus = (userId, status) => {
//   return db
//     .collection("users")
//     .doc(userId)
//     .set({ status }, { merge: true });
// };

// export const editUser = (userId, data) => {
//   return db
//     .collection("users")
//     .doc(userId)
//     .set(data, { merge: true });
// };

// export const getAdmin = (userId) => {
//   return db
//     .collection("admins")
//     .doc(userId)
//     .get();
// };

// export const deleteUser = userId => {
//   return db
//     .collection("users")
//     .doc(userId)
//     .delete();
// };

// export const getAllProducts = () => {
//   return db.collection("products").get();
// };

// export const getAllCategoriese = () => {
//   return db.collection("categories").get();
// };

// export const addProduct = payload => {
//   return db.collection("products").add(payload);
// };

// export const editProduct = (docId, payload) => {
//   let refer = db.collection("products").doc(docId);
//   if (payload.pricePerStrip)
//     refer.update({
//       pricePerUnit: firebase.firestore.FieldValue.delete()
//     });
//   else if (payload.pricePerUnit)
//     refer.update({
//       pricePerStrip: firebase.firestore.FieldValue.delete()
//     });

//   return refer.set(payload, { merge: true });
// };

// export const storeInStorage = file => {
//   const fileName = Math.round(Math.random() * 1000000);
//   const data = new Promise((resolve, reject) => {
//     storage
//       .ref()
//       .child("/images/" + fileName + ".jpg")
//       .put(file)
//       .then(() => {
//         storage
//           .ref()
//           .child("/images/" + fileName + ".jpg")
//           .getDownloadURL()
//           .then(uri => {
//             resolve(uri);
//           });
//       });
//   });
//   return data;
// };

// export const deleteProduct = docId => {
//   return db
//     .collection("products")
//     .doc(docId)
//     .delete();
// };

// export const addCategory = payload => {
//   return db.collection("categories").add(payload);
// };

// export const editCategory = (docId, payload) => {
//   return db
//     .collection("categories")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const deleteCategory = docId => {
//   return db
//     .collection("categories")
//     .doc(docId)
//     .delete();
// };

// export const getAllPromoCodes = () => {
//   return db.collection("promoCodes").get();
// };

// export const addPromo = payload => {
//   return db.collection("promoCodes").add(payload);
// };

// export const editPromo = (docId, payload) => {
//   return db
//     .collection("promoCodes")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const deletePromo = docId => {
//   return db
//     .collection("promoCodes")
//     .doc(docId)
//     .delete();
// };

// export const getAllBrands = () => {
//   return db.collection("brands").get();
// };

// export const addBrand = payload => {
//   return db.collection("brands").add(payload);
// };

// export const editBrand = (docId, payload) => {
//   return db
//     .collection("brands")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const deleteBrand = docId => {
//   return db
//     .collection("brands")
//     .doc(docId)
//     .delete();
// };

// export const getSubCategories = docId => {
//   return db
//     .collection("categories")
//     .doc(docId)
//     .collection("sub-categories")
//     .get();
// };

// export const addSubCat = (docId, payload) => {
//   return db
//     .collection("categories")
//     .doc(docId)
//     .collection("sub-categories")
//     .add(payload);
// };

// export const editSubCat = (catId, docId, payload) => {
//   return db
//     .collection("categories")
//     .doc(catId)
//     .collection("sub-categories")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const deleteSubCat = (catId, docId) => {
//   return db
//     .collection("categories")
//     .doc(catId)
//     .collection("sub-categories")
//     .doc(docId)
//     .delete();
// };

// export const getAllFormula = () => {
//   return db.collection("productFormula").get();
// };

// export const addFormula = payload => {
//   return db.collection("productFormula").add(payload);
// };

// export const editFormula = (docId, payload) => {
//   return db
//     .collection("productFormula")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const getAllManufacturer = () => {
//   return db.collection("productManufacturer").get();
// };

// export const addManufacturer = payload => {
//   return db.collection("productManufacturer").add(payload);
// };

// export const editManufacturer = (docId, payload) => {
//   return db
//     .collection("productManufacturer")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const addImageSlide = payload => {
//   return db.collection("slides").add(payload);
// };

// export const getImageSlides = () => {
//   return db.collection("slides").get();
// };

// export const updateSliderImageStatus = (status, docId) => {
//   return firebase
//     .firestore()
//     .collection("slides")
//     .doc(docId)
//     .set(
//       {
//         active: status
//       },
//       { merge: true }
//     );
// };

// export const deleteImageSlide = docId => {
//   return firebase
//     .firestore()
//     .collection("slides")
//     .doc(docId)
//     .delete();
// };

// export const getOrders = () => {
//   return firebase
//     .firestore()
//     .collection("orders")
//     .get();
// };

// export const editOrders = (obj, id) => {
//   return firebase
//     .firestore()
//     .collection("orders")
//     .doc(id)
//     .set(obj, { merge: true });
// };

// export const updateHomeVisibilityCategories = (status, docId) => {
//   return db.collection("categories").doc(docId)
//     .set(
//       {
//         active: status
//       },
//       { merge: true }
//     );
// };

// export const getHomeText = () => {
//   return db.collection("homeText").get();
// };

// export const updateHomeText = (updatedTextHtml, docId) => {
//   return db.collection("homeText").doc(docId)
//     .set(
//       {
//         text: updatedTextHtml
//       },
//       { merge: true }
//     );
// };

// export const updateTrendingText = (updatedText, docId) => {
//   return db.collection("homeText").doc(docId)
//     .set(
//       {
//         trendingText: updatedText
//       },
//       { merge: true }
//     );
// };

// export const getAllShippings = () => {
//   return db.collection("shipping").get();
// };

// export const addShipping = payload => {
//   return db.collection("shipping").add(payload);
// };

// export const editShipping = (docId, payload) => {
//   return db
//     .collection("shipping")
//     .doc(docId)
//     .set(payload, { merge: true });
// };

// export const deleteShipping = docId => {
//   return db
//     .collection("shipping")
//     .doc(docId)
//     .delete();
// };

// export const getFreeShippingCost = () => {
//   return db.collection("freeShipping").get();
// };

// export const updateFreeShippingCost = (updatedCost, docId) => {
//   return db.collection("freeShipping").doc(docId)
//     .set(
//       {
//         freeShippingCost: updatedCost
//       },
//       { merge: true }
//     );
// };

// export const getBanner = () => {
//   return db.collection("banner").get();
// };

// export const updateBanner = (banner, docId) => {
//   return db.collection("banner").doc(docId)
//     .set(
//       {
//         image: banner
//       },
//       { merge: true }
//     );
// };

// export const getUser = docId => {
//   return db.collection("users").doc(docId).get();
// }

// export const getFeedbacks = _ => {
//   return db.collection("feedbacks").get();
// }

// export const getPrescriptions = _ => {
//   return db.collection("prescriptions").get();
// }

// export const forgetPassword = email => {
//   return auth.sendPasswordResetEmail(email);
// };

// export const changePassword = newPassword => {
//   return auth.currentUser.updatePassword(newPassword);
// };
