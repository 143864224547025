import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import sideBarReducer from './reducers/sideBarReducer';
import userReducer from './reducers/users';
import cuisineReducer from './reducers/cuisineReducer';

const allReducers = {
  authReducer,
  sideBarReducer,
  userReducer,
  cuisineReducer,
};

export default combineReducers(allReducers);
