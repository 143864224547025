import React from "react";
import { style } from "./styles";
// import { signIn, googleAuth, editUser, getAdmin, updateToken } from "../../config/firebase";
import { Form, Button, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
// import { messaging } from '../../init-fcm.js'
// import swal from "sweetalert";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      pass: "",
      loader: false,
      buttonLoader: false
    };
  }

  login = async () => {
    const { history } = this.props;
    const { email, pass } = this.state;

    if (!email) {
      this.errorToast("Enter Email");
      return;
    }

    if (!pass) {
      this.errorToast("Enter Password");
      return;
    }
    this.nevigate("/users")
  };

  nevigate = route => {
    const { history } = this.props;
    history.push(route);
  };

  errorToast = message => toast.error(message, { containerId: "Error" });

  render() {
    const { email, pass, buttonLoader } = this.state;
    return (
      <div>
        <ToastContainer
          containerId={"Error"}
          closeOnClick={false}
          closeButton={false}
          draggable={false}
        />
        <div style={style.mainContainer}>
          <div style={style.authWrapper}>
            <div style={style.authHeader}>
              <img src={logo} style={{ width: 75, height: 75, marginRight: 10 }} />
              <div style={style.borderWrapper}>
                <p style={style.headerTitle}>
                  Welcome to <span style={style.makeBold}>Admin</span>
                  <span style={style.changeColor}> PANEL</span>
                </p>
                <p style={style.headerSubHeading}>Login your account easily</p>
              </div>
            </div>
            <div style={style.fieldsWrapper}>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={pass}
                    onChange={e => this.setState({ pass: e.target.value })}
                  />
                  <Form.Text className="text-muted" style={style.forgotPassword} onClick={() => this.nevigate("/forgotpassword")}>
                    Forgot Password?
                  </Form.Text>
                </Form.Group>
                {!buttonLoader ? <Button
                  variant="primary"
                  type="button"
                  block
                  onClick={this.login}
                >
                  Login
                </Button> : <Button variant="primary" disabled block>
                    <Spinner
                      as="span"
                      animation="border"
                      style={{ width: '20px', height: "20px" }}
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}Loading...
                </Button>}

                <Button
                  variant="outline-primary"
                  type="button"
                  style={style.btnCreateAccount}
                  onClick={() => this.nevigate("/signup")}
                >
                  Create Account
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
