import React from "react";
import { style } from "./styles";
// import { forgetPassword } from "../../config/firebase";
import { Form, Button, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
// import swal from "sweetalert";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      loader: false
    };
  }

  // sendEmail = async (e) => {
  //   e.preventDefault();
  //   const { email } = this.state;
  //   try {
  //     if (!email) {
  //       this.errorToast("Enter Email!");
  //       return;
  //     }
  //     this.setState({ loader: true });
  //     forgetPassword(email);
  //     swal("Sended", "Check your email to reset your password", "success");
  //     this.setState({ email: "", loader: false });
  //   } catch (error) {
  //     this.errorToast("Error while sending email!");
  //     console.log("TCL: Login -> sendEmail -> error", error);
  //     this.setState({ email: "", loader: false });
  //   }
  // };

  nevigate = () => {
    const { history } = this.props;
    history.push("/login");
  };

  errorToast = message => toast.error(message, { containerId: "Error" });

  render() {
    const { email, loader } = this.state;
    return (
      <div>
        <ToastContainer
          containerId={"Error"}
          closeOnClick={false}
          closeButton={false}
          draggable={false}
        />
        <div style={style.mainContainer}>
          <div style={style.authWrapper}>
            <div style={style.authHeader}>
              <img src={logo} style={{ width: 75, height: 75, marginRight: 10 }} />
              <div style={style.borderWrapper}>
                <p style={style.headerTitle}>
                  Welcome to <span style={style.makeBold}>Admin</span>
                  <span style={style.changeColor}> PANEL</span>
                </p>
                <p style={style.headerSubHeading}>
                  Recover your account easily
                </p>
              </div>
            </div>
            <div style={style.fieldsWrapper}>
              <Form /*onSubmit={this.sendEmail}*/>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>

                {!loader ? <Button
                  variant="primary"
                  type="submit"
                  block
                >
                  Send
                </Button> : <Button variant="primary" disabled block>
                    <Spinner
                      as="span"
                      animation="border"
                      style={{ width: '20px', height: "20px" }}
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}Loading...
                </Button>}

                <Button
                  variant="outline-primary"
                  type="button"
                  style={style.btnCreateAccount}
                  onClick={this.nevigate}
                >
                  Back to Login
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
