const sideBarReducer = (state = {}, action) => {
  switch (action.type) {
    case "TOGGLE_SIDEBAR": {
      return { ...state, sideBar: !action.sideBar };
    }
    default : {
      return state
  }
  }
};

export default sideBarReducer;
