import React, { useEffect, useState } from 'react';
import { style } from './styles';
import { Modal, Button, Form, Table, Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import AddButton from '../../components/AddButton';

import Firebase from '../../config/firebase';

const storage = Firebase.storage();

//Methods
// import {
//   addImageSlide,
//   storeInStorage,
//   getImageSlides,
//   updateSliderImageStatus,
//   deleteImageSlide
// } from "../../config/firebase";

const Slides = (props) => {
  const [state, setState] = useState({
    modal: false,
    modalTitle: 'Add Slides',
    slides: [],
    loader: false,
    docId: '',
    slideImage: '',
    modalLoader: false,
  });

  // componentDidMount() {
  //   this.getSlides();
  // }

  // getSlides = async () => {
  //   try {
  //     let temp = [];
  //     const response = await getImageSlides();
  //     console.log("TCL: Slides -> getSlides -> response.size", response.size);
  //     if (response.size) {
  //       response.forEach(doc => {
  //         console.log(doc.data());
  //         temp.push({ ...doc.data(), docId: doc.id });
  //       });
  //       this.setState({
  //         slides: temp,
  //         loader: false
  //       });
  //     } else {
  //       this.setState({
  //         slides: temp,
  //         loader: false
  //       });
  //     }
  //   } catch (error) {
  //     this.setState({
  //       slides: [],
  //       loader: false
  //     });
  //     console.log("TCL: Slides -> getSlides -> error", error);
  //   }
  // };

  const toggleModal = () => {
    let { modal } = state;

    setState({
      ...state,
      modal: !modal,
      modalTitle: 'Add Slides',
    });
  };

  const getAllSlide = async () => {
    const sliderArray = [];
    try {
      const slides = await Firebase.firestore().collection('sliders').get();
      if (!slides.docs.length) {
        return;
      }

      for (var slide of slides.docs) {
        const data = slide.data();
        data.key = slide.id;
        sliderArray.push(data);

        if (sliderArray.length === slides.docs.length) {
          setState({ ...state, slides: sliderArray });
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const addSlides = async () => {
    const { slideImage } = state;
    try {
      if (!slideImage) {
        errorToast('Select Slide Image!');
        return;
      }
      setState({ ...state, modalLoader: true });
      storage
        .ref(`images/${slideImage.name}`)
        .put(slideImage)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            await Firebase.firestore()
              .collection('sliders')
              .add({ image: downloadURL, active: true });
            getAllSlide();
            setState({
              ...state,
              modalLoader: false,
              modal: false,
              slideImage: '',
            });
          });
        });
    } catch (error) {
      setState({
        ...state,
        modalLoader: false,
        modal: false,
        slideImage: '',
      });
      console.log('TCL: Slides -> addSlides -> error', error);
      // setState({...state, modalLoader: false });
    }
  };

  // editPromoCodeModal = value => {
  //   let { modal } = this.state;

  //   this.setState({
  //     modal: !modal,
  //     modalTitle: "Edit Slides",
  //     docId: value.docId
  //   });
  // };

  const editSlide = async () => {
    try {
    } catch (error) {
      console.log('TCL: Slides -> editSlides -> error', error);
    }
  };

  // deleteSlideConfirmation = docId => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this slide",
  //     icon: "warning",
  //     buttons: true,
  //     dangerMode: true
  //   }).then(willDelete => {
  //     if (willDelete) {
  //       this.deleteSlide(docId);
  //     }
  //   });
  // };

  const deleteSlide = async (key) => {
    try {
      await Firebase.firestore().collection('sliders').doc(key).delete();
      getAllSlide();
    } catch (error) {
      console.log('TCL: Slides -> error', error);
    }
  };

  const errorToast = (message) =>
    toast.error(message, { containerId: 'Error' });

  const handleText = (value) => {
    // console.log(state, value);
    setState({
      ...state,
      slideImage: value,
    });
  };

  useEffect(() => {
    getAllSlide();
  }, []);

  const activeDeactive = async (status, key) => {
    try {
      if (status) {
        await Firebase.firestore()
          .collection('sliders')
          .doc(key)
          .update({ active: false });
        getAllSlide();
      } else {
        await Firebase.firestore()
          .collection('sliders')
          .doc(key)
          .update({ active: true });
        getAllSlide();
      }
    } catch (error) {
      console.log('TCL: Slides -> activeDeactive -> error', error);
    }
  };

  const { modal, modalTitle, loader, slides, modalLoader } = state;
  console.log('TCL: Slides -> render -> slides', slides);
  const { history } = props;
  return (
    <div>
      <ToastContainer
        containerId={'Error'}
        closeOnClick={false}
        closeButton={false}
        draggable={false}
      />

      <Modal
        show={modal}
        size='lg'
        onHide={(_) => setState({ ...state, modal: false })}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='slideImage'>
            <Form.Control
              type='file'
              accept='image/gif, image/jpeg, image/png'
              onChange={(e) => handleText(e.currentTarget.files[0])}
            />
          </Form.Group>

          {!modalLoader ? (
            <Button
              variant='primary'
              block
              onClick={modalTitle === 'Add Slides' ? addSlides : editSlide}
            >
              {modalTitle === 'Add Slides' ? 'Add' : 'Update'}
            </Button>
          ) : (
            <Button variant='primary' disabled block>
              <Spinner
                as='span'
                animation='border'
                style={{ width: '20px', height: '20px' }}
                size='sm'
                role='status'
                aria-hidden='true'
              />{' '}
              Loading...
            </Button>
          )}
        </Modal.Body>
      </Modal>

      <SideBar history={history} activeScreen='slides' />
      <Header history={history} />
      <div style={style.mainContainer}>
        <div style={style.tableContainer}>
          <Table striped bordered style={{ width: '95%' }}>
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th>#</th>
                <th>Slide Image</th>
                <th>Active/Deactive</th>
                <th>Delete</th>
              </tr>
            </thead>
            {loader ? (
              <div style={style.loaderWrapper}>Loading.....</div>
            ) : (
              <React.Fragment>
                {!slides.length ? (
                  <div style={style.loaderWrapper}>Slides Not Found.....</div>
                ) : (
                  <tbody>
                    {slides.map((value, index) => {
                      return (
                        <tr key={index} style={{ textAlign: 'center' }}>
                          <td style={{ verticalAlign: 'inherit' }}>
                            {index + 1}
                          </td>
                          <td>
                            <img
                              src={value.image}
                              style={{ width: 350, height: 100 }}
                            />
                          </td>
                          <td style={{ verticalAlign: 'inherit' }}>
                            <Button
                              variant={'primary'}
                              block
                              onClick={() =>
                                activeDeactive(value.active, value.key)
                              }
                            >
                              {value.active ? 'Deactive' : 'Active'}
                            </Button>
                          </td>
                          <td style={{ verticalAlign: 'inherit' }}>
                            <Button
                              variant='danger'
                              block
                              onClick={() => deleteSlide(value.key)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </React.Fragment>
            )}
          </Table>
        </div>
      </div>
      <AddButton handler={toggleModal} />
    </div>
  );
};

export default Slides;
