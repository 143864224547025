import React from "react";
import { style } from "./styles";
import { connect } from "react-redux";
import Chart from "react-apexcharts";
import { Table } from "react-bootstrap";
import productImg from "../../assets/images/product.jpg";

// Components
import Header from "../../components/Header";
import SideBar from "../../components/SideBar";

class Reports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loader: false,
            monthlyReport: [],
            allTotal: 0,
            products: []
        };
    }

    componentDidMount() {
        // this.getAllOrdersMonthly();
        // this.getProducts();
    }

    render() {
        let { loader } = this.state;
        let products = [{ title: "Sealed Pack", saleCount: 100, pricePerUnit: 500, boxQty: 3, saleCount: 13, discount: 10 }, { title: "Sealed Pack", saleCount: 120, pricePerUnit: 500, boxQty: 3, saleCount: 13, discount: 10 }, { title: "Sealed Pack", saleCount: 150, pricePerUnit: 500, boxQty: 3, saleCount: 13, discount: 10 }]
        let total
        let monthlyReport = [{ month: 1, orderLength: 4, totalSale: 100 }, { month: 2, orderLength: 4, totalSale: 100 }, { month: 3, orderLength: 4, totalSale: 100 }]
        products = products.filter(el => el.saleCount).sort((a, b) => { return b.saleCount - a.saleCount })

        let { history } = this.props;
        return (
            <div>
                <SideBar history={history} activeScreen="Reports" />
                <Header history={history} />
                <div style={style.mainContainer}>
                    <div style={style.subContainer}>
                        <h1>Monthly Order View</h1>

                        {loader ? (
                            <div style={style.loaderWrapper}>Loading.....</div>
                        ) : !!monthlyReport.length ? (
                            <Chart
                                options={{
                                    xaxis: {
                                        categories: monthlyReport.map(data => data.month)
                                    },
                                    title: {
                                        text: 'Orders Trends by Month',
                                        align: 'center'
                                    },
                                    stroke: {
                                        curve: 'straight'
                                    },
                                    toolbar: {
                                        show: true,
                                        tools: {
                                            download: true
                                        }
                                    },
                                    yaxis: [
                                        {
                                            labels: {
                                                formatter: val => val
                                            }
                                        },
                                        {
                                            seriesName: "Sales"
                                        }
                                    ]
                                }}
                                series={[
                                    {
                                        name: "Orders",
                                        data: monthlyReport.map(data => data.orderLength)
                                    },
                                    {
                                        name: "Sales",
                                        data: monthlyReport.map(data => data.totalSale)
                                    }
                                ]}
                                style={{ marginLeft: '190px' }}
                                type="line"
                                width="700"
                            />
                        ) : <div style={style.loaderWrapper}>No Orders</div>}
                       
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);