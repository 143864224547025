import React from "react";
import { style } from "./styles";

function SideBar(props) {
  const navigate = (route) => {
    const { history } = props;
    history.replace(route);
  };
  const { activeScreen = "users" } = props;
  return (
    <div style={style.mainContainer}>
      <p style={style.headerTitle}>
        Admin <span style={style.secondaryTitle}>PANEL</span>
      </p>

      <div style={style.menuContainer}>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={2}
          style={{
            ...style.menuItem,
            color: activeScreen === "users" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/users")}
        >
          Manage Users
        </p>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={3}
          style={{
            ...style.menuItem,
            color: activeScreen === "slides" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/slides")}
        >
          Manage Slides
        </p>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={6}
          style={{
            ...style.menuItem,
            color: activeScreen === "cuisine" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/cuisine")}
        >
          Manage Cuisine
        </p>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={10}
          style={{
            ...style.menuItem,
            color: activeScreen === "prices" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/prices")}
        >
          Manage Prices
        </p>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={15}
          style={{
            ...style.menuItem,
            color: activeScreen === "reviews" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/reviews")}
        >
          Feedback &amp; Ratings
        </p>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={18}
          style={{
            ...style.menuItem,
            color: activeScreen === "interview" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/interview")}
        >
          Manage Interviews
        </p>
        <p
          onKeyDown={(e) =>
            (e.keyCode == 13 || e.keyCode == 32) && e.target.click()
          }
          tabIndex={20}
          style={{
            ...style.menuItem,
            color: activeScreen === "Reports" ? "#007bff" : "#FFF",
          }}
          onClick={() => navigate("/Reports")}
        >
          Reports
        </p>
      </div>
    </div>
  );
}

export default SideBar;
