import { Colors } from "../../utils";

export const style = {
  mainContainer: {
    width: 230,
    height: "100vh",
    position: "absolute",
    zIndex: 100,
    backgroundColor: '#231f20',
    display: "flex",
    flexDirection: "column",
  },// #1c74bc
  // #231f20
  headerTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginLeft: 15,
    marginTop: 4,
    cursor: "pointer",
    color: 'white'
  },
  secondaryTitle: {
    color: '#007bff'
  },
  menuContainer: {
    width: "100%",
    height: "auto",
    lineHeight: '40px',
    marginTop: 20,
    color: Colors.background,
    fontWeight: "bold"
  },
  menuItem: {
    marginLeft: 15,
    cursor: "pointer",
    fontSize: 'large'
  }
};
