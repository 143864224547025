import React, { useState, useEffect } from 'react';
import { style } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Form, Table, Spinner } from 'react-bootstrap';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import AddButton from '../../components/AddButton';
import {
  getAllCuisines,
  addCuisine,
  deleteCuisine,
} from '../../redux/actions/cuisineAction';

const Cuisine = (props) => {
  const [state, setState] = useState({
    modal: false,
    modalTitle: 'Add Category',
    categories: [1, 2],
    loader: false,
    cuisine: '',
    updateKey: '',
    modalLoader: false,
  });

  const dispatch = useDispatch();
  const cuisines = useSelector((state) => state.cuisineReducer.cuisines);

  const errorToast = (message) =>
    toast.error(message, { containerId: 'Error' });

  const toggleModal = () => {
    let { modal } = state;

    setState({
      ...state,
      modal: !modal,
      modalTitle: 'Add Category',
      cuisine: '',
    });
  };

  const closeModal = () => {
    setState({ ...state, modal: false, modalLoader: false });
  };

  const handleChange = (e) => {
    setState({ ...state, cuisine: e.target.value });
  };

  const onAddCuisine = (modalTitle) => {
    const { cuisine, updateKey } = state;
    if (!cuisine) {
      return alert('Cuisine is required');
    }
    setState({ ...state, modalLoader: true });
    dispatch(addCuisine(cuisine, closeModal, modalTitle, updateKey));
  };

  const onDeleteCuisine = (key) => {
    dispatch(deleteCuisine(key));
  };

  const editModal = (name, key) => {
    setState({
      ...state,
      modal: true,
      modalTitle: 'Edit Cuisine',
      cuisine: name,
      updateKey: key,
    });
  };

  useEffect(() => {
    dispatch(getAllCuisines());
  }, []);

  const { modal, modalTitle, categories, loader, cuisine, modalLoader } = state;
  const { history } = props;
  return (
    <div>
      <ToastContainer
        containerId={'Error'}
        closeOnClick={false}
        closeButton={false}
        draggable={false}
      />

      <Modal
        show={modal}
        size='lg'
        onHide={(_) => setState({ ...state, modal: false })}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='categoryName'>
            <Form.Label>Cuisine Name</Form.Label>
            <Form.Control
              onChange={handleChange}
              type='text'
              placeholder='Cuisine Name'
              value={cuisine}
              // onChange={e =>
              //   this.handleText("category", e.currentTarget.value)
              // }
            />
          </Form.Group>

          {!modalLoader ? (
            <Button
              onClick={() =>
                onAddCuisine(modalTitle === 'Add Category' ? 'Add' : 'Update')
              }
              variant='primary'
              block
              // onClick={
              //   modalTitle === "Add Category"
              //     ? this.addCategory
              //     : this.editCategory
              // }
            >
              {modalTitle === 'Add Category' ? 'Add' : 'Update'}
            </Button>
          ) : (
            <Button variant='primary' disabled block>
              <Spinner
                as='span'
                animation='border'
                style={{ width: '20px', height: '20px' }}
                size='sm'
                role='status'
                aria-hidden='true'
              />{' '}
              Loading...
            </Button>
          )}
        </Modal.Body>
      </Modal>

      <SideBar history={history} activeScreen='cuisine' />
      <Header history={history} />
      <div style={style.mainContainer}>
        <div style={style.tableContainer}>
          <Table striped bordered style={{ width: '95%' }}>
            <thead>
              <tr>
                <th>#</th>
                <th>Cuisine Name</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            {loader ? (
              <div style={style.loaderWrapper}>Loading.....</div>
            ) : (
              <React.Fragment>
                {!cuisines.length ? (
                  <div style={style.loaderWrapper}>Cuisines Not Found.....</div>
                ) : (
                  <tbody>
                    {cuisines.map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{value.name}</td>

                          <td>
                            <Button
                              variant='success'
                              style={{ width: '100%' }}
                              onClick={() => editModal(value.name, value.key)}
                            >
                              Edit
                            </Button>
                          </td>
                          <td>
                            <Button
                              variant='danger'
                              style={{ width: '100%' }}
                              onClick={() => onDeleteCuisine(value.key)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </React.Fragment>
            )}
          </Table>
        </div>
      </div>
      <AddButton handler={toggleModal} />
    </div>
  );
};

export default Cuisine;
