import React, { useEffect, useState } from 'react';
import { style } from './styles';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Button, Table, Image, Form } from 'react-bootstrap';
import locationIcon from '../../assets/images/location.png';
import backIcon from '../../assets/images/back.png';

import swal from 'sweetalert';
import MapContainer from '../../components/GoogleMap';
import Geocode from 'react-geocode';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import {
  getAllUsers,
  deleteUser,
  getAllChef,
  toggleActive,
  toggleFeatured
} from '../../redux/actions/userAction';

//Methods
// import {
//   getAllUsers,
//   changeUserStatus,
//   deleteUser
// } from "../../config/firebase";

Geocode.setApiKey('AIzaSyCUc-HZ2Avd4gF8IED9QKWeWGJY7zHHKLg');
Geocode.enableDebug();

const Users = (props) => {
  const [state, setState] = useState({
    //users: [1, 2],
    loader: false,
    docId: '',
    activeTab: 0,
    activeScreen: 'table',
    location: null
  });

  const dispatch = useDispatch();
  const users = useSelector((state) => state.userReducer.users);

  const onDeleteUser = (key) => {
    swal("Are you sure?", {
      icon: "error",
      buttons: true
    }).then(ok => {
      if (ok) {
        dispatch(deleteUser(key, activeTab));
        swal("User Successfully Deleted", { icon: 'success' });
      }
    })
  };

  const handleFeatured = (key, status) => {
    swal("Are you sure?", {
      icon: "info",
      buttons: true
    }).then(ok => {
      if (ok) {
        dispatch(toggleFeatured(key, status))
        swal("Successfully Done", { icon: 'success' });
      }
    })
  };

  const onToggleActive = (status, key) => {
    swal(`Do you want to ${status === "active" ? "active" : "block"} this user?`, {
      icon: status === "active" ? "info" : "error",
      buttons: true
    }).then(ok => {
      if (ok) {
        dispatch(toggleActive(status, key, activeTab));
        swal("Successfully Done", { icon: 'success' });
      }
    })
  };

  useEffect(() => {

    Geocode.fromLatLng("48.8583701", "2.2922926", "AIzaSyCROfNz5Fd3i6-R8M7S4FMhCwk66ERq1uk").then(
      (response) => {
        console.log(response)
        const address = response.results[0].formatted_address;
        console.log(address, 'zxcxzczc');
      },
      (error) => {
        console.error(error);
      }
    );
  }, []);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  const { loader, activeTab, activeScreen } = state;
  const { history } = props;

  return (
    <div>
      <SideBar history={history} activeScreen='users' />
      <Header history={history} />
      {/* {!!users.length && <a tabIndex={19} style={style.exportButton} className="btn btn-success" onClick={this.exportCsv}>Export User List</a>} */}
      (
      {activeScreen === 'table' ? (
        <React.Fragment>
          <div>
            <div style={style.tabContainer}>
              {activeTab === 0 ? (
                <h5 style={style.activeTab}>Customers</h5>
              ) : (
                  <h5
                    style={style.inactiveTab}
                    onClick={(_) => {
                      dispatch(getAllUsers());
                      setState({ ...state, activeTab: 0 });
                    }}
                  >
                    Customers
                  </h5>
                )}
              {activeTab === 1 ? (
                <h5 style={style.activeTab}>Chefs</h5>
              ) : (
                  <h5
                    style={style.inactiveTab}
                    onClick={(_) => {
                      setState({ ...state, activeTab: 1 });
                      dispatch(getAllChef());
                    }}
                  >
                    Chefs
                  </h5>
                )}
            </div>
          </div>
          ) (
          <div style={style.mainContainer}>
            <div style={style.tableContainer}>
              <Table striped bordered style={{ width: '95%' }}>
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Full Name</th>
                    <th>Email</th>
                    <th>Location</th>
                    {activeTab === 1 && <th>Feature</th>}
                    <th>Active/Block</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                {loader ? (
                  <div style={style.loaderWrapper}>Loading.....</div>
                ) : (
                    <React.Fragment>
                      {!users.length ? (
                        <div style={style.loaderWrapper}>
                          Users Not Found.....
                        </div>
                      ) : (
                          <tbody>
                            {users.map((value, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{value.name}</td>
                                  <td>{value.email}</td>
                                  <td style={style.locationTd}>
                                    ABC Area
                                <Button
                                      style={{ marginLeft: 6 }}
                                      onClick={() => {
                                        setState({
                                          ...state,
                                          location: value.location
                                            ? value.location
                                            : null,
                                          activeScreen: 'map',
                                        });
                                      }}
                                    >
                                      <Image src={locationIcon} rounded />
                                    </Button>
                                  </td>
                                  {activeTab === 1 && <td>
                                    <Form.Check
                                      id={`switch-${index}`}
                                      type='switch'
                                      value={value.key}
                                      checked={value.featured}
                                      onChange={_ => handleFeatured(value.key, !value.featured)}
                                      label={value.featured ? "Featured" : "Unfeatured"}
                                    />
                                  </td>}
                                  <td>
                                    {value.active ? (
                                      <Button
                                        variant='danger'
                                        style={{ width: '100%' }}
                                        onClick={() =>
                                          onToggleActive('blocked', value.key)
                                        }
                                      >
                                        Block
                                      </Button>
                                    ) : (
                                        <Button
                                          variant='primary'
                                          style={{ width: '100%' }}
                                          onClick={() =>
                                            onToggleActive('active', value.key)
                                          }
                                        >
                                          Active
                                        </Button>
                                      )}
                                  </td>
                                  <td>
                                    <Button
                                      onClick={() =>
                                        onDeleteUser(value.key)
                                      }
                                      variant='danger'
                                      style={{ width: '100%' }}
                                    // onClick={() => this.deleteUser(value.docId)}
                                    >
                                      Delete
                                </Button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                    </React.Fragment>
                  )}
              </Table>
            </div>
          </div>
        </React.Fragment>
      ) : activeScreen === 'map' ? (
        <>
          <Button
            style={style.backbtn}
            onClick={() => {
              setState({
                ...state,
                activeScreen: 'table',
              });
            }}
          >
            <Image src={backIcon} style={{ marginTop: -4 }} />
          </Button>
          <div style={style.mapContainer}>
            <MapContainer location={state.location} />
          </div>
        </>
      ) : (
            <React.Fragment />
          )}
    </div>
  );
};

export default Users;
