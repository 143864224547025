import React, { Component } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMarker: {},
      showingInfoWindow: false,
    };
  }

  onMapClicked = (props) => {
    let { showingInfoWindow } = this.state;
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: {},
      });
    }
  };

  onMarkerClick = (props, marker, e) => {
    let { activeMarker, showingInfoWindow } = this.state;
    if (!Object.keys(activeMarker).length && !showingInfoWindow) {
      this.setState({
        activeMarker: marker,
        showingInfoWindow: true,
      });
    }
  };

  render() {
    let { activeMarker, showingInfoWindow } = this.state;
    return (
      <Map
        google={this.props.google}
        zoom={14}
        initialCenter={{
          lat: this.props.location ? this.props.location.latitude : 0,
          lng: this.props.location ? this.props.location.longitude : 0,
        }}
        center={{
          lat: this.props.location ? this.props.location.latitude : 0,
          lng: this.props.location ? this.props.location.longitude : 0,
        }}
        style={{ width: '80%', height: '78vh' }}
        onClick={this.onMapClicked}
      >
        <Marker onClick={this.onMarkerClick} name='Current location' />
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          <div>
            <h4>location name</h4>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCROfNz5Fd3i6-R8M7S4FMhCwk66ERq1uk',
})(MapContainer);
