import Firebase from '../../config/firebase';

export const getAllUsers = () => async (dispatch) => {
  try {
    const usersArray = [];
    const users = await Firebase.firestore().collection('users').get();

    for (var user of users.docs) {
      const data = user.data();
      data.key = user.id;
      usersArray.push(data);
      if (usersArray.length === users.docs.length) {
        dispatch({ type: 'GET_ALL_USERS', payload: usersArray });
      }
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const getAllChef = () => async (dispatch) => {
  try {
    const chefArray = [];
    const chefs = await Firebase.firestore().collection('chef-profile').get();

    for (var chef of chefs.docs) {
      const data = chef.data();
      data.key = chef.id;
      chefArray.push(data);
      if (chefArray.length === chefs.docs.length) {
        dispatch({ type: 'GET_ALL_USERS', payload: chefArray });
      }
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const deleteUser = (key, activeTab) => async (dispatch) => {
  try {
    await Firebase.firestore().collection(activeTab === 0 ? 'users' : activeTab === 1 ? "chef-profile" : "").doc(key).delete();
    if (activeTab === 0) {
      dispatch(getAllUsers());
    } else {
      dispatch(getAllChef());
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const toggleActive = (status, key, activeTab) => async (dispatch) => {
  try {
    if (status === 'blocked') {
      await Firebase.firestore()
        .collection(activeTab === 0 ? 'users' : activeTab === 1 ? "chef-profile" : "")
        .doc(key)
        .update({ active: false });
      if (activeTab === 0) {
        dispatch(getAllUsers());
      }
      else {
        dispatch(getAllChef());
      }
    }
    else {
      await Firebase.firestore()
        .collection(activeTab === 0 ? 'users' : activeTab === 1 ? "chef-profile" : "")
        .doc(key)
        .update({ active: true });
      if (activeTab === 0) {
        dispatch(getAllUsers());
      }
      else {
        dispatch(getAllChef());
      }
    }
  } catch (err) {
    console.log(err.message);
  }
};

export const toggleFeatured = (key, status) => async dispatch => {
  try {
    await Firebase.firestore().collection("chef-profile").doc(key).update({ featured: status });
    dispatch(getAllChef());
  } catch (err) {
    console.log(err.message)
  }
}