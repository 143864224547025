import React from "react";
import { style } from "./styles";
import { Button } from "react-bootstrap";
// import { logout } from "../../config/firebase";
import logo from "../../assets/images/logo.png";

function Header(props) {
  const { selectVal, onSelected, history } = props;

  const signout = async () => {
    const { history } = props;

    try {
      // await logout();
      localStorage.removeItem("userId");
      history.replace("/login");
    } catch (error) {
      console.log("error while doing logout", error);
    }
  };


  return (
    <div style={style.headerContainer}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={style.headerTitle}>
          Admin <span style={style.secondaryTitle}>PANEL</span>
        </p>
        <img src={logo} style={{ width: 45, height: 45, marginLeft: 35 }} />
      </div>
      {/* {selectVal && (
        <select tabIndex={16} onChange={(e) => { onSelected(e.target.value) }} >
        {selectVal.map(val => <option value={!val.name ? val : val.name}>{!val.name ? val.toUpperCase() : val.name.toUpperCase()}</option>)}
        </select>
      )} */}
      <div>
        <Button
          tabIndex={17}
          variant="success"
          onClick={() => history.push('/dashboard')}
        >
          My Dashboard
        </Button>
        {" "}
        <Button
          tabIndex={18}
          variant="danger"
          style={style.btnLogout}
          onClick={() => signout()}
        >
          Logout
      </Button>
      </div>
    </div>
  );
}

export default Header;
