import React from "react";
import { style } from "./styles";

function AddButton(props) {
  const { handler } = props;
  return (
    <div style={style.buttonContainer}>
      <div tabIndex={20} style={style.addButton} onClick={handler} onKeyDown={e => (e.keyCode == 13 || e.keyCode == 32) && e.target.click()}>
        <p style={style.addButtonText}>+</p>
      </div>
    </div>
  );
}

export default AddButton;
