export const style = {
  mainContainer: {
    width: "100%",
    height: "70vh",
    display: "flex",
    justifyContent: "center",
    overflowY: "hidden",
  },
  tableContainer: {
    width: "83%",
    height: "87vh",
    marginLeft: 220,
    display: "flex",
    paddingBottom: 60,
    justifyContent: "center",
    marginTop: 10,
    overflowY: "scroll",
  },
  loaderWrapper: {
    width: "79%",
    height: 50,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  exportButton: {
    marginLeft: "55%",
    marginTop: "10px",
    color: "white",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "32vw",
    width: "50%",
    marginTop: 10,
    cursor: "pointer",
  },
  activeTab: {
    background: "#007bff",
    color: "white",
    width: "50%",
    padding: 10,
    textAlign: "center",
    margin: 0,
    borderBottom: "3px solid black",
  },
  inactiveTab: {
    background: "white",
    textAlign: "center",
    width: "50%",
    padding: 10,
    margin: 0,
    border: "1px solid black",
  },
  locationTd: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mapContainer: {
    marginLeft: 250,
    marginTop: 8
  },
  backbtn: {
    marginLeft: 250,
    width: 50,
    display: "flex",
    justifyContent: "center",
    height: 40,
    positive: "absolute",
    marginTop: -15,
    marginBottom: 5
  }
};
