export const style = {
    mainContainer: {
        width: "100%",
        height: "75vh",
        textAlign: "center",
    },
    subContainer: {
        width: "83%",
        height: "87vh",
        marginLeft: "220px",
        marginTop: "10px",
        overflowY: "scroll"
    },

    loaderWrapper: {
        width: "79%",
        height: 50,
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    productImage: {
        width: 70,
        height: 70
    },

};