export const style = {
  mainContainer: {
    width: "100%",
    height: "75vh",
    marginTop: "50px",
  },
  nameHeading: { 
    marginLeft: '220px' 
  },
  formLabel: {
    textAlign: 'right', 
    fontWeight: 'bold' 
  }
};
