import React from 'react';
import { style } from './styles';
import { connect } from 'react-redux';
import { Modal, Button, Form, Table, Spinner, Dropdown } from 'react-bootstrap';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';

import Firebase from '../../config/firebase';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';

//Methods

class Prices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingFees: '',
      subscriptionFees: '',
    };
  }

  updateBookingFees = async () => {
    if (!this.state.bookingFees) {
      return alert('Please select booking fees');
    }
    try {
      await Firebase.firestore()
        .collection('bookingFees')
        .doc('fees')
        .set({ amount: this.state.bookingFees });
      this.setState({ bookingFees: '' });
    } catch (err) {
      console.log(err.message);
    }
  };

  updatesubscriptionFees = async () => {
    if (!this.state.subscriptionFees) {
      return alert('Please select subscription  fees');
    }
    try {
      await Firebase.firestore()
        .collection('subscriptionFees')
        .doc('fees')
        .set({ amount: this.state.subscriptionFees });
      this.setState({ subscriptionFees: '' });
    } catch (err) {
      console.log(err.message);
    }
  };

  subscriptionFeesHandler = (e) => {
    this.setState({ subscriptionFees: e.target.value });
  };
  bookingFeesHandler = (e) => {
    console.log(e.target.value);
    this.setState({ bookingFees: e.target.value });
  };

  render() {
    const { history } = this.props;
    return (
      <div>
        <ToastContainer
          containerId={'Error'}
          closeOnClick={false}
          closeButton={false}
          draggable={false}
        />

        <SideBar history={history} activeScreen='prices' />
        <Header history={history} />
        <div style={style.mainContainer}>
          <h1>Booking Fees</h1>
        </div>
        {/* <AddButton handler={this.toggleModal} /> */}
        <div style={style.dropdown}>
          <Form.Group
            onChange={this.bookingFeesHandler}
            controlId='exampleForm.ControlSelect1'
            style={{ width: 120 }}
          >
            <Form.Control as='select'>
              <option>5</option>
              <option>10</option>
              <option>15</option>
              <option>20</option>
              <option>25</option>
              <option>30</option>
              <option>35</option>
              <option>40</option>
              <option>45</option>
              <option>50</option>

              <option>55</option>
            </Form.Control>
          </Form.Group>

          <Button
            onClick={this.updateBookingFees}
            style={{ marginLeft: 20, height: 38 }}
          >
            Update
          </Button>
        </div>
        <div style={style.monthlySub}>
          <h1>Monthly subscription fees</h1>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            height: 38,
          }}
        >
          <Form.Group>
            <Form.Control
              onChange={this.subscriptionFeesHandler}
              value={this.state.subscriptionFees}
              type='number'
              className='w-100'
            />
          </Form.Group>
          <Button
            onClick={this.updatesubscriptionFees}
            style={{ marginLeft: 20 }}
          >
            Update
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Prices);
