import React, { useState, useEffect } from 'react';
import { style } from './styles';

import { Button, Form } from 'react-bootstrap';

// Components
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';

import Firebase from '../../config/firebase';

const Interview = (props) => {
  const [iframe, setIframe] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');

  const handlerIframe = (e) => {
    setIframe(e.target.value);
  };

  const getIframe = async () => {
    const iframe = await Firebase.firestore()
      .collection('interview')
      .doc('video')
      .get();

    if (!iframe.data()) {
      return setIframeUrl('');
    }

    setIframeUrl(iframe.data().iframe);
  };

  const updateIframe = async () => {
    if (!iframe) {
      return alert('Iframe is required');
    }

    await Firebase.firestore()
      .collection('interview')
      .doc('video')
      .set({ iframe });

    getIframe();
    setIframe('');
  };

  useEffect(() => {
    getIframe();
  }, []);
  const { history } = props;

  return (
    <div>
      <SideBar history={history} activeScreen='interview' />
      <Header history={history} />
      {/* {!!users.length && <a tabIndex={19} style={style.exportButton} className="btn btn-success" onClick={this.exportCsv}>Export User List</a>} */}
      ( (
      <div style={style.videoWindow}>
        <iframe
          width='75%'
          height='410'
          src={iframeUrl}
          frameborder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen
        ></iframe>
      </div>
      <div style={style.inputContainer}>
        <Form.Group
          style={{
            display: 'flex',
          }}
        >
          <Form.Control
            type='text'
            placeholder='Normal text'
            className='w-75'
            onChange={handlerIframe}
            value={iframe}
          />
          {/* */}
          <Button onClick={updateIframe} style={{ marginLeft: 2, width: 105 }}>
            Update
          </Button>
        </Form.Group>
      </div>
      )
    </div>
  );
};

export default Interview;
